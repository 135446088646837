.product-modal {
    margin: auto;
    
  }
  
  .product-modal .modal-body {
    padding: 20px;
  }
  .modal-lg{
    --bs-modal-width:70% !important;
  }