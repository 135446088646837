.landing-page-editor-container{
    min-height : 96vh;
}
.input-sku {
    height:350px;
}
/* LandingPageForm.css */
.validation-status {
    /* height: 100%; */
    white-space: pre-wrap;
}
.valid-skus {
    background-color: #d4edda; /* light green background for valid SKUs */
    border-color: #c3e6cb; /* matching border color */
}
.invalid-skus {
    background-color: #f8d7da; /* light red background for invalid SKUs */
    border-color: #f5c6cb; /* matching border color */
}
.no-visuals {
    background-color: #f5db6a !important; /* light red background for invalid SKUs */
    border-color: #f5c6cb !important; /* matching border color */
}
/* .form-currency{
    
} */