.show-vis-tiles-img{
    object-fit:cover;
    border-radius: 10px;
    height: auto;
    width: 100%;
    cursor: pointer;
}
.show-vis-tiles-img:hover{
    border: 2px solid #919191;
}


.show-vis-tiles-img-body{
    position: relative;
    padding: 1px;
    margin: 5px;
    background-color: #f5f3f3;
    border: 1px solid #e7e7e7;
    align-items: center;
    border-radius: 10px;
    width:250px;
    height: auto;
}
.video-content{
    width:500px !important;
}
.show-vis-tiles-show-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.show-vis-header{
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px 0;
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    align-items: center;
}
.show-vis-text-small{
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 400;
    color: #919191;
}
.tiles-show-size-input{
    min-width: 90px;
    width: auto;
}