.form-message{
   height: 278px;

}
.svg-icon-confirmation{
    height: 100px;
    width: 100px;
}
.confirmation-container{
    text-align: center;
}
.img-representant{
    max-width: 350px;
}