.selected-products-dropdown {
    position: fixed;
    bottom: -400px;
    right: 50px;
    width: 300px;
    transition: height 0.3s ease-in-out;
    z-index: 1000;
    overflow: hidden;
    margin: 0px;
  }
  
  .selected-products-dropdown.show {
    transition: height 0.3s ease-in-out;
   bottom:0px;
  }


.dropdown-toggle-btn.highlighted {
  font-size: 1.2em; /* Zwiększenie rozmiaru tekstu */
  background-color: #ff8189;
  
}
  
  .dropdown-toggle-btn {
    transition: 0.1s ease-in-out;
    background-color: #ff8189af;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
  }
  .dropdown-toggle-btn:hover {
    transition: 0.1s ease-in-out;
    background-color: #ff8189;
  }
  
  .dropdown-menu-kurwa {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 0 0 10px 10px;
    height: 400px;
    overflow-y: auto;
    text-align: center;
  }
  
  .selected-products-list {
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;
  }
  
  .selected-products-list li {
    margin-bottom: 5px;
  }
  