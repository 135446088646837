/* VisualBrowser.css */
.visual-browser-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;
}

.visual-browser-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.close-button-svg {
    width: 40px; /* Upewnij się, że ikona ma odpowiedni rozmiar */
    height: 40px;
}

.visual-browser-image, .visual-browser-video {
    max-width: 100%;
    max-height: 80vh;
    margin: auto;
}

.visual-browser-video {
    width: 100%;
    height: auto;
}
