.form--row {
    margin: 0;
}

.form--container {
    padding: 10px;
    margin-bottom: 10px;
    margin-top:50px;
}

.form--textarea {
    width: 100%;
    min-height: 500px;
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    resize: vertical;
}

.form--card {
    border-radius: 20px;
    background-color: #e6e6e6;
    padding: 20px;
    height: 100%;
}
