.landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    background-color: #ffffff;
    padding-bottom: 30px;
  }
  
  .landing-page-header-container {
    width: 100%;
    height: 250px;
    position: relative;
    flex-grow: 0; /* Dodane, aby zapobiec rozciąganiu */

  }
  
  .landing-page-header-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center -250px;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    background-image: url('../../../public/img/landing-page-banner.jpg');
  }
  
  .landing-page-header-text {
    margin-left: 165px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .landing-page-header-text p {
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .landing-page-header-img {
      background-attachment: scroll;
      background-image: none;
    }
  
    .landing-page-header-text {
      margin: 0;
      padding: 10px;
      font-size: 16px;
      color: #000000;
    }
  }
  
  .landing-page-cards-container {
    padding-top: 30px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom:30px;
  }
  
  .landing-page-categories-container  .landing-page-cards-container .landing-page-categories-container{
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: rows;
    justify-content: center;
    width: 100%;
    padding-bottom:30px;
  }
  
  .error-404-text{
    font-size: 50px;
    color: #ff8189;
    margin-top: 100px;
  }
  .error-404-container{
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .line-below{
    
    border-bottom: 1px solid #ff8189;
    width: 100%;
    margin-top: 20px;
    margin-bottom:30px;
  }

 .select-products{
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding-top: 0px !important;
 }
 .product-select-header{
  background-color: #cfe2ff;
  color: white;
  padding: 10px 0px 10px 20px;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  margin-top: 0px !important;
  margin-bottom: 15px;
  color:#000000

 }