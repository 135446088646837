/* LandingPageEditor.css */
.table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .table-landing-pages {
    width: 100% !important;
    table-layout: fixed;
  }
  
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 150px;
    vertical-align: top;
  }
  .col-creation-date {
    width: 100px;
  }
  
  .col-exp-date {
    width: 100px;
  }
  
  .col-language {
    width: 60px;
  }
  .col-page-key {
    width: 100px;
  }
  .col-skus {
    width: 300px;

  }

  .button-create{
    max-width: 300px;
  }

  .col-offer-creator {
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Ustal odpowiednią szerokość */
  }