.product-card {
  position: relative;
    width: 300px;
    height: 440px;
    background-color: #fafafa;
    border-radius: 10px;
    border: 1px solid  rgba(151, 151, 151, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin: 10px;
  }
  .product-card:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  
  .image-container {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding:10px;
    min-height: 295px;
  }
  
  .product-info {
    width: 100%;
    text-align: center;
    padding: 10px;
    padding-top:0px;
    box-sizing: border-box;
  }
  
  .product-code {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .product-name {
    font-size: 14px;
    font-weight: normal;
    margin: 3px 0;
  }
  
  .product-size {
    font-size: 12px;
    color: #666;
    margin: 3px 0;
  }
  .product-price{
    position:absolute;
    bottom: 10px;
    right:10px;
    font-weight: 500;
    margin:0px;
  }
  .product-ean{
    font-size: 11px;
    color: #666;
    margin: 5px 0;
  }
  .product-card-link{
    text-decoration: none; /* Usuń podkreślenie */
    color: inherit; /* Użyj koloru tekstu rodzica */
  }
  .product-card .product-checkbox {
    position: absolute;
    top: 6px;
    left: 10px;
    width: 35px;
    height: 35px;
    z-index: 100;
    box-shadow: rgba(10, 10, 30, 0.25) 2px 6px 11px 0px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
  .lazy-load-image-background.blur.lazy-load-image-loaded{
    width: 100%;
    min-width: 295px;
    padding:10px
  }


  

  
  @media (max-width: 920px) {
    .product-card {
      width: 290px;
    }
  }
  
