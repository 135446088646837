.nav-separator .nav-item:not(:first-child) .nav-link {
  position: relative;

}

.nav-separator .nav-item:not(:first-child) .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 50%;
  background-color: #ddd; /* Możesz dostosować kolor linii */

}

.nav-separator .nav-link.active{
  color: #ee4550 !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 10px 20px -15px,
               rgba(0, 0, 0, 0.45) 0px -10px 20px -15px !important;
  
  
}

.nav-bar-top {
    display: flex;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
    color: #1b1b1b;
    padding: 0px;
    justify-content: center; /* Umożliwia równomierne rozmieszczenie elementów */
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 80px;
  }
  
  .nav-bar-top-logo {
    height: 50px;
    padding: 0px;
  }
  
  .nav-bar-top .navbar-collapse {
    display: flex;
    justify-content: center; /* Wyśrodkuj menu nawigacyjne */
    /* flex-grow: 1; */
  }
  
  .nav-bar-top .navbar-nav {
    display: flex;
    justify-content: center; /* Wyśrodkuj elementy menu */
    /* flex-grow: 1; */
  }
  
  .nav-bar-top a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    margin: 0px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
  }
  
.nav-bar-top a:hover {
    color: #d8a4a4;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 10px 20px -18px, rgba(0, 0, 0, 0.45) 0px -10px 20px -18px !important;
  }
  
/* 
.nav-bar-top .store-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; 
  width: 150px; 
} */

  
  @media (max-width: 768px) {
    .nav-bar-top-logo {
      height: 40px;
    }
  
    .nav-bar-top .store-link {
      height: 40px; /* Dopasowanie wysokości do logo na mniejszych ekranach */
    }
    .navbar-collapse {
      background-color: #f8f8f8; /* Dodaj tło */
    }
  }
  
