
.sku-button{
    background-color: #eeeeee;
    border: 1px solid #d3d3d3;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
}
.sku-button:hover{
    background-color: #000;
    color: #fff;
}
.sku-list-button-selected{
    background-color: #000;
    color: #fff;
}
.sku-list {
    height: 70vh; /* Ustaw wysokość na 100% widocznej części okna */
    overflow-y: auto; /* Włącz pionowe przewijanie, gdy jest potrzebne */
    margin-bottom:15px;
  }
.sku-list-base{
    margin: 0 px auto;
    padding: 0 px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    background-color: #f5f3f3;
} 
.sku-list-search{
    width:-webkit-fill-available;
    margin-bottom: 3px;
    padding: 10px;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
}
.image-tiles-draggable{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tiles-container{
    padding-top:20px;
    justify-content: center;
    text-align: center;
}

.tiles-img-body{
    padding: 1px;
    margin: 1px;
    background-color: #f5f3f3;
    border: 1px solid #e7e7e7;
    align-items: center;
    border-radius: 10px;
}
.tiles-img-img{
    object-fit:cover;
    border-radius: 10px;
}
.tiles-img-hover{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(10, 10, 10);
    background-color: rgba(238, 238, 238, 0.8)
}
.tiles-img-btn-del{
    position:absolute;
    top: 0px;
    right: 0px;
    margin-top:5px;
    margin-right:5px;
}

.tiles-show-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tiles-show-size-input{
    width: 50px;
    margin: 5px;
    padding: 5px;
}

.button-save-spacing{
    margin-top: 10px;
    margin-bottom: 10px;
   
}
.sku-list-ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    }
      
.main-page-bg{
    /* position: fixed;
    bottom: 0px;
  
    background-color: #f7f7f7;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: -1; */
}
.main-page-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(77, 77, 77);
    height: 90vh;

}
 
PopUpMessages styles
.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:2;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
}

.popup-buttons {
    margin-top: 20px;
    padding:10px;
    display: flex;
    justify-content: space-evenly ;
}


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.popup-corner-small{
    position: fixed;
    top: 10px;
    left: 10px;
    margin-top:5px;
    margin-right:5px;
    border-radius: 10px;
    padding:0px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    z-index: 1021;

}
.success{
    background-color: rgba(105, 216, 78, 0.747);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.error{
    background-color: rgb(228, 90, 90);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.popup-corner-small.fade-in {
    animation: fadeIn 0.5s forwards;
}

.popup-corner-small.fade-out {
    animation: fadeOut 0.5s forwards;
}
.popup-corner-small--text{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0px;
}