.change-language-menu {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Opcjonalne: dodaj margines, jeśli potrzebujesz */
  }
 
.language-flag {
  width:18px;
}
.language-btn {
  border: none;
  padding:5px;
  margin: 0px;
  background-color: transparent;
  opacity: 0.3;
  filter: grayscale(70%);
}
.language-btn:hover {
  opacity: 1;
  filter: grayscale(0%);
}
.language-btn.selected {
  opacity: 1;
  filter: grayscale(0%);
}
.language-selector{
  margin-right:5px;
  padding-right:5px;
  border-right:rgba(0, 0, 0, 0.2) 1px solid;
}