landing-page-editor-container {
    min-height: 90vh;
}
/* LandingPageEditor.css */

.container {
    max-width: 1700px;
    /* padding: 0px; */
}




.text-center {
    text-align: center;
}

@media (max-width: 576px) {
    .form-control {
        width: 100%;
    }

    .btn {
        width: 100%;
        margin-top: 10px;
    }

    .input-sku {
        height: auto;
    }

    .my-5 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .fs-2 {
        font-size: 1.5rem;
    }
}
