.headers-category-line {
    flex-grow: 1;
  height: 2px; /* Grubość linii */
  background-color: #ff8189; /* Kolor linii */
  padding-right:10px;
  max-width: 1200px;
  margin-top: 10px;
  }
  
  .headers-category-text{
    padding-left:10px;
    font-size: 24px;
    font-weight: 500;
    margin-right: 10px; /* Odstęp między tekstem a linią */
  }
  .headers-category-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0 px;
    margin-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;
    justify-content: center;
  }


  .headers-category-text-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .headers-category-text {
    font-size: 1.5rem;
    margin-right: 10px;
  }
  
  
  @media (max-width: 768px) {
    .headers-category-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .headers-category-line {
      width: 100%;
      margin-top: 5px;
    }
  
    .headers-category-text {
      margin-right: 0;
    }
  }
