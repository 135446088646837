.h-300px {
    height:300px;
}
.h-90px{
    height:80px;

}
.form-control{
    margin-top:5px;
}

  
.tab-styles {
    color: #000 !important;
    border-radius: 5px;
    margin: 2px;
    padding: 10px;
    cursor: pointer;
  }

  
/* .amazon {
    background-color: rgb(161, 176, 204) !important;
}
.shopify {
    background-color: #b2d3c2 !important;
} */

/* .nav-link.active {
   box-shadow: #00000044 0px 5px 5px;
    border: 0px;
} */
/* .nav-link.active.amazon{
    background-color: rgb(90, 109, 145) !important;
    color: #fff !important; 
}
.nav-link.active.shopify{
    background-color: #466656 !important;
    color: #fff !important; 
} */


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #f0f7ff;
    border: 1px solid #8facce;
}